var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"map_container",attrs:{"justify-center":"","id":"map"}},[_c('v-layout',{attrs:{"justify-center":""}},[(_vm.mapReady)?_c('GmapMap',{ref:"mapRef",staticClass:"map_style",attrs:{"center":{ lat: 41.8548271, lng: 12.4545949 },"zoom":16,"map-type-id":"roadmap"}},[_c('GmapMarker',{attrs:{"position":{ lat: 41.854593, lng: 12.456442 },"label":{
          text: 'Laboratorio Roscioli',
          fontSize: '14px',
          className: 'lab_marker_position',
          color: 'black',
        },"animation":_vm.google.maps.Animation.DROP,"icon":{ url: _vm.home_icon, scaledSize: new _vm.google.maps.Size(48, 48) }}}),_c('GmapMarker',{attrs:{"position":{ lat: 41.8945667, lng: 12.4742778 },"label":{
          text: 'Forno Roscioli',
          fontSize: '14px',
          className: 'lab_marker_position',
          color: 'black',
        },"animation":_vm.google.maps.Animation.DROP,"icon":{ url: _vm.home_icon, scaledSize: new _vm.google.maps.Size(48, 48) }}}),_vm._l((_vm.couriers),function(item){return _c('GmapMarker',{key:item.id,ref:"couriersMarkers",refInFor:true,attrs:{"position":{ lat: item.latitude, lng: item.longitude },"label":{
          text: _vm.labelTextForItem(item),
          fontSize: '14px',
          className: 'car_position',
          color: 'white',
        },"animation":_vm.google.maps.Animation.DROP,"clickable":true,"icon":{ url: _vm.car_icon, scaledSize: new _vm.google.maps.Size(48, 48) }},on:{"click":function($event){return _vm.openInfoWindow(item)}}})}),_vm._l((_vm.couriers),function(item){return _c('gmap-info-window',{key:'iw' + item.id,ref:"infoWindow",refInFor:true,attrs:{"options":{
          maxWidth: 300,
          pixelOffset: { width: 0, height: -35 },
        },"position":{ lat: item.latitude, lng: item.longitude },"opened":item.iwopen},on:{"closeclick":function($event){item.iwopen = false}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.infoWindowTextForItem(item))}})])})],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }