var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"justify-center":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('messageBox',{ref:"msgBox"}),_c('div',{staticStyle:{"height":"10px"}}),_c('v-flex',{attrs:{"md12":""}},[_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.clearLogs()}}},[_vm._v(_vm._s(_vm.$t("logsmanager-clear")))])],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{ref:"severity_id",staticClass:"minfield",attrs:{"outlined":"","dense":"","label":_vm.$t('logs-mngs-level'),"items":_vm.severities,"item-text":"name","item-value":"id"},model:{value:(_vm.filters.severityId),callback:function ($$v) {_vm.$set(_vm.filters, "severityId", $$v)},expression:"filters.severityId"}})],1),_c('v-col',[_c('v-autocomplete',{ref:"user_id",staticClass:"minfield",attrs:{"outlined":"","dense":"","label":_vm.$t('logs-mngs-user'),"items":_vm.users,"item-text":"name","item-value":"id"},model:{value:(_vm.filters.userId),callback:function ($$v) {_vm.$set(_vm.filters, "userId", $$v)},expression:"filters.userId"}})],1),_c('v-col',[_c('v-select',{ref:"period",attrs:{"outlined":"","dense":"","label":_vm.$t('logs-mngs-period'),"items":_vm.getPeriodFilters(),"item-text":"name","item-value":"id"},model:{value:(_vm.filters.filterByPeriod),callback:function ($$v) {_vm.$set(_vm.filters, "filterByPeriod", $$v)},expression:"filters.filterByPeriod"}})],1),_c('v-col',[_c('v-dialog',{ref:"dialog_start_date",attrs:{"return-value":_vm.filters.filterByCustomDateStart,"width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filters, "filterByCustomDateStart", $event)},"update:return-value":function($event){return _vm.$set(_vm.filters, "filterByCustomDateStart", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"disabled":_vm.filters.filterByPeriod != 9,"dense":"","outlined":"","label":_vm.$t('logs-mngs-datestart'),"readonly":""},model:{value:(_vm.start_date_formatted),callback:function ($$v) {_vm.start_date_formatted=$$v},expression:"start_date_formatted"}},on))]}}]),model:{value:(_vm.date_start_modal),callback:function ($$v) {_vm.date_start_modal=$$v},expression:"date_start_modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},on:{"input":function($event){return _vm.$refs.dialog_start_date.save(_vm.filters.filterByCustomDateStart)}},model:{value:(_vm.filters.filterByCustomDateStart),callback:function ($$v) {_vm.$set(_vm.filters, "filterByCustomDateStart", $$v)},expression:"filters.filterByCustomDateStart"}})],1)],1),_c('v-col',[_c('v-dialog',{ref:"dialog_end_date",attrs:{"return-value":_vm.filters.filterByCustomDateEnd,"width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filters, "filterByCustomDateEnd", $event)},"update:return-value":function($event){return _vm.$set(_vm.filters, "filterByCustomDateEnd", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"disabled":_vm.filters.filterByPeriod != 9,"dense":"","outlined":"","label":_vm.$t('logs-mngs-dateend'),"readonly":""},model:{value:(_vm.end_date_formatted),callback:function ($$v) {_vm.end_date_formatted=$$v},expression:"end_date_formatted"}},on))]}}]),model:{value:(_vm.date_end_modal),callback:function ($$v) {_vm.date_end_modal=$$v},expression:"date_end_modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},on:{"input":function($event){return _vm.$refs.dialog_end_date.save(_vm.filters.filterByCustomDateEnd)}},model:{value:(_vm.filters.filterByCustomDateEnd),callback:function ($$v) {_vm.$set(_vm.filters, "filterByCustomDateEnd", $$v)},expression:"filters.filterByCustomDateEnd"}})],1)],1)],1),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"primary","icon":"mdi-home","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-1 font-weight-light card-header"},[_vm._v(" "+_vm._s(_vm.$t("logsmanager-title"))+" ")])]},proxy:true}])},[_c('v-divider',{staticClass:"mt-3"}),(_vm.operationInProgress)?_c('v-progress-circular',{attrs:{"indeterminate":true,"rotate":0,"size":32,"width":4,"color":"primary"}}):_vm._e(),(!_vm.operationInProgress)?_c('v-data-table',{attrs:{"items-per-page":_vm.perPage,"must-sort":true,"headers":_vm.headers,"items":_vm.logs,"options":_vm.options,"server-items-length":_vm.logsCount,"loading":_vm.loading,"footer-props":_vm.dataTableFooterOptions},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.level",fn:function(ref){
var item = ref.item;
return [(item.level == 0)?_c('span',[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-checkbox-multiple-blank-circle")])],1):_vm._e(),(item.level == 1)?_c('span',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-checkbox-multiple-blank-circle")])],1):_vm._e(),(item.level == 2)?_c('span',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-checkbox-multiple-blank-circle")])],1):_vm._e()]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTime")(item.time))+" ")]}},{key:"item.context",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.context.toUpperCase())+" ")]}}],null,false,2739742740)}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }